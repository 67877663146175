/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base menu (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/front/bootstrap.scss';
import '../css/front/app.scss';
import '../css/front/menu.scss';
import '../css/front/admin.scss';
import '../css/front/content.scss';
import '../css/front/block/welcomeImage.scss'
import '../css/front/block/page.scss';
import '../css/front/block/home.scss';
import '../css/front/block/block.scss';
import '../css/front/block/form.scss';
import '../css/front/block/specific.scss';
import '../css/front/block/blog.scss';
import '../css/front/block/timeline.scss';


//AOS
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();




// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';


const $ = require('jquery');
// create global $ and jQuery variables
global.$ = global.jQuery = $;
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');
require('@fortawesome/fontawesome-free/css/all.min.css');

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();
});
